.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding-top: 12rem;
}

.full-width, .crossmintButton-0-2-1{
  width: 100% !important;
}

.css-leotc6{
  padding: .1rem 12px !important;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: 'lights';
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: 'lights' !important;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: 'lights' !important;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: 'spark';
  font-weight: 900;
  font-style: normal;
}

.grayed{
  color: gray;
}

img{
  width: 100%;
}

.home_nft_3{
  margin-top: 10rem;
  background-image: url('./assets/img/dog_3.png');
  background-size: cover;
  background-position: center;
  height: 100%;
}

.title_home{
  display: flex;
  margin-top: 2rem !important;
}

.btn_buy{
  background-color: #FFC0CB !important;
  border: none !important;
  width: 12rem;
  border-radius: 20px !important;
  padding-top: .7rem !important;
  padding-bottom: .7rem !important;
  margin-top: 1rem !important;
}

.btn_other{
  background-color: white !important;
  border: none !important;
  width: 12rem;
  border-radius: 20px !important;
  padding-top: .7rem !important;
  padding-bottom: .7rem !important;
  margin-top: 1rem !important;
  color: black !important;
}

.tw-connect-wallet{
  border-radius: 20px !important;
}

h1{
  font-size: 3.6rem !important;
}

.subtitle{
  font-size: 1.1rem !important;
}

.text-justify{
  text-align: justify;
}

.img_icon_c{
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}

.img_icon_c > img{
  width: 4rem;
}

.h6_6{
  font-size: 1.3rem;
  padding-top: .4rem;
  padding-bottom: .3rem;
}

.desc_6{
  font-size: .9rem;
}

.box_p{
  border-radius: 20px;
  background-color: rgba(243, 243, 243, 0.267);
  padding: 2rem 1rem;
  height: 100%;
}

.nft_sale{
  background-color: rgba(243,244,246,.7);
  position: relative;
  padding: 2rem 1rem;
}

.representationflex{
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  position: relative;
}

.nft_representation{
}  

.nft_representation > img{
  border-radius: 20px;
  border: 10px solid white;
  width: 30vw;
}

.container_sale{
  background-color: white;
  min-height: 80%;
  border-radius: 20px;
  width: 40%;
  left: 10rem;
  top: 3rem;
  
  position: absolute;
  padding: 3rem 1.5rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.price_sale{
  color: rgb(34 197 94);
  font-size: 2rem;
  border: 2px solid rgb(34 197 94);
  border-radius: 10px;
  padding: .5rem 1rem;
}

.price_eth{
  color: #212529;
  font-size: 1.3rem;
  padding: .5rem 0rem;
}

.sale_countdown_t{
  font-size: 1.1rem;
}

.btnflexh{
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
}

.icon_text{
  font-size: 1rem !important;
}

a{
  cursor: pointer;
}

.result_c{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1rem;
  padding-bottom: 3rem;
  padding-top: 1rem;

  width: 60%;
}

.result_c > a{
  text-decoration: none;
  color: white !important;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  padding: .5rem;
}

.result_c > a:nth-child(1){
  background-color: #1976D2;
}

.result_c > a:nth-child(3){
  background-color: #969696;
}

.result_c > a:nth-child(2){
  background-color: #5824ad;
}


.dis_a{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 5rem;
}

@media only screen and (max-width: 1000px){
  .container_sale{
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 4;
    margin-top: 20em;
    min-height: fit-content;
  }

  .nft_representation{
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  .nft_representation > img{
    width: 70%;
    margin: 0rem 5rem;
  }
} 

@media only screen and (max-width: 600px) {
  .comm_img{
    display: none;
  }
  .App {
    padding-top: 7rem;
  }
  h1 {
    font-size: 2.8rem !important;
  }

  .subtitle {
    font-size: 1rem !important;
  }

  .btn_buy{
    width: 100%;
  }

  .grid_rep{
    padding-top: 4rem !important;
  }

  .container_sale{
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 4;
    margin-top: 15rem;
    min-height: inherit;
  }

  .nft_representation{
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  .nft_representation > img{
    width: 90%;
    margin: 0rem 1.2rem;
  }

  .socials_cont{
    flex-direction: column-reverse;
    padding-top: 3rem;
  }
}

@media only screen and (max-width: 320px) {
  h1 {
    font-size: 2rem !important;
  }

  .btnflexh{
    flex-wrap: wrap;
  }
}