@font-face {
  font-family: 'spark';
  src: url('./assets/fonts/coffee_spark/Coffee\ Spark.otf');
}

@font-face {
  font-family: 'lights';
  src: url('./assets/fonts/light_stories/Light\ Stories.ttf');
}
.MuiBox-root > li > p{
  font-family: 'spark' !important;
  font-weight: 900;
  font-style: normal;
}
.MuiButtonBase-root{
  font-family: 'spark' !important;
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'lights' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'lights' !important;
}

